import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import PageHeader from './components/layout/pageHeader';
import Dashboard from './pages/dashboard/Dashboard';
import Settings from './pages/settings/Settings';

import config from './amplifyconfiguration.json';
import NotFound from './pages/notfound/NotFound';
import Search from './pages/search/Search';
import Login from './pages/Auth/login';
import { UserInformationContext, UserInformation } from './Contexts/UserContext';
import Landing from './pages/landing/landing';
import Signup from './pages/Auth/Signup';
import ProtectedRoute from './components/utils/ProtectedRoute';
import ResetConfirmation from './pages/Auth/ResetConfirmation';
import EmailConfirmation from './pages/Auth/confirm-email';

config.oauth.domain = 'auth.foreadvantagegolf.com';
Amplify.configure(config);
console.log(config);
function App() {
  const [userInfo, setUserInfo] = useState<UserInformation | null>(null);
  const contextValue = useMemo(() => ({ userInfo, setUserInfo }), [userInfo, setUserInfo]);
  return (
    <Router>
      <UserInformationContext.Provider value={contextValue}>
        <div>
          <Routes>
            <Route
              path="/"
              element={<Landing />}
            />
            <Route
              path="/signup"
              element={<Signup />}
            />
            <Route
              path="/login"
              element={<Login />}
            />
            <Route
              path="/forgot-password"
              element={<ResetConfirmation />}
            />
            <Route
              path="/confirm-email"
              element={<EmailConfirmation />}
            />
            <Route
              path="/search"
              element={
                <ProtectedRoute>
                  <PageHeader />
                  <Search />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <PageHeader />
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <PageHeader />
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={<NotFound />}
            />
          </Routes>
        </div>
      </UserInformationContext.Provider>
    </Router>
  );
}

export default App;
