import React from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';

function NoSearchesFound() {
  return (
    <div className="noSearchesFoundContainer">
      <FaMagnifyingGlass className="noSearchesFoundIcon" />
      <h1>No searches found</h1>
      <p>It looks like you don&apos;t have any searches. Create one on the search page!</p>
    </div>
  );
}

export default NoSearchesFound;
