import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Calendar } from 'lucide-react';

interface FeatureCardProps {
  icon: typeof Calendar;
  title: string;
  description: string;
}

export function FeatureCard({ icon: CardIcon, title, description }: FeatureCardProps) {
  return (
    <div className="bg-gray-800 p-6 rounded-lg flex items-start space-x-4">
      <CardIcon className="w-8 h-8 text-yellow-500 flex-shrink-0" />
      <div>
        <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
        <p className="text-gray-400">{description}</p>
      </div>
    </div>
  );
}

interface TechnologyCardProps {
  title: string;
  description: string;
  imageUrl: string;
}
export function TechnologyCard({ title, description, imageUrl }: TechnologyCardProps) {
  const [isFlipped, setIsFlipped] = useState(false);
  return (
    <div
      className="relative w-44 h-64 group"
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
    >
      <div
        className={`absolute w-full h-full transition-all duration-500 [transform-style:preserve-3d] ${isFlipped ? '[transform:rotateY(180deg)]' : ''}`}
      >
        {/* Front of the card */}
        <div className="absolute w-full h-full bg-gray-800 rounded-lg p-6 flex flex-col items-center justify-between [backface-visibility:hidden]">
          <img
            src={imageUrl}
            alt={title}
            className="w-36 h-28 object-contain rounded-lg "
          />
          <h3 className="text-xl font-semibold text-white text-center">{title}</h3>
        </div>

        {/* Back of the card */}
        <div className="absolute w-full h-full bg-gray-800 rounded-lg p-6 flex items-center justify-center [backface-visibility:hidden] [transform:rotateY(180deg)]">
          <p className="text-white text-center">{description}</p>
        </div>
      </div>
    </div>
  );
}
