import React, { useState } from 'react';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Spinner from '../../components/utils/Spinner';

function EmailConfirmation() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const mode = location.state?.mode;
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [code, setCode] = useState('');

  // Redirect if no email in state
  if (!email) {
    navigate(mode === 'signup' ? '/signup' : '/login');
    return null;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      const result = await confirmSignUp({
        username: email,
        confirmationCode: code,
      });
      if (result.isSignUpComplete === true) {
        navigate('/login', {
          state: {
            message: 'Email confirmed successfully. Please log in.',
          },
        });
      }
    } catch (err: any) {
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    setError(null);
    setIsResending(true);

    try {
      await resendSignUpCode({ username: email });
      setError('A new code has been sent to your email.');
    } catch (err: any) {
      setError(err.message || 'Error sending code. Please try again.');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <header className="bg-black py-4 px-6 sticky top-0 shadow-lg">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link
            to={mode === 'signup' ? '/signup' : '/login'}
            className="flex items-center group"
          >
            <ArrowLeft className="w-5 h-5 text-yellow-500 mr-2 transition-transform group-hover:-translate-x-1" />
            <span className="text-yellow-500 text-sm">
              Back to {mode === 'signup' ? 'Sign Up' : 'Login'}
            </span>
          </Link>
        </div>
      </header>

      <main className="flex-grow flex items-center justify-center px-4 py-8">
        <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-sm border border-gray-700">
          <div className="flex items-center justify-center mb-6">
            <img
              src="golf_bot_image.jpeg"
              alt="ForeAdvantage Golf Logo"
              className="w-12 h-12 rounded-full border-2 border-yellow-500"
            />
          </div>

          <h1 className="text-2xl font-bold text-white text-center mb-2">
            Verify Your Email
          </h1>

          <p className="text-gray-400 text-center text-sm mb-6">
            We sent a verification code to:
            <br />
            <span className="text-white font-medium">{email}</span>
          </p>

          <div className="h-12 mb-6">
            {error && (
              <div
                className={`${
                  error.includes('has been sent')
                    ? 'bg-green-500/10 border-green-500/50 text-green-500'
                    : 'bg-red-500/10 border-red-500/50 text-red-500'
                } px-4 py-3 rounded-lg text-sm border`}
              >
                {error}
              </div>
            )}
          </div>

          <form
            onSubmit={handleSubmit}
            className="space-y-4"
          >
            <div>
              <label
                htmlFor="code"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Verification Code
                <input
                  id="code"
                  name="code"
                  type="text"
                  required
                  className="w-full px-4 py-2 mt-1 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 outline-none text-white"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter code"
                  maxLength={6}
                />
              </label>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full px-4 py-2 bg-yellow-500 text-gray-900 rounded-lg hover:bg-yellow-400 transition-all font-medium disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            >
              {isLoading ? <Spinner /> : 'Verify Email'}
            </button>
          </form>

          <div className="mt-6 text-center">
            <button
              type="button"
              onClick={handleResendCode}
              disabled={isResending}
              className="text-sm text-yellow-500 hover:text-yellow-400 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isResending ? 'Sending...' : 'Resend code'}
            </button>
          </div>

          <p className="mt-6 text-center text-gray-400 text-sm">
            Wrong email?{' '}
            <Link
              to={mode === 'signup' ? '/signup' : '/login'}
              className="text-yellow-500 hover:text-yellow-400"
            >
              Go back
            </Link>
          </p>
        </div>
      </main>

      <footer className="bg-black py-4 text-center text-gray-400">
        <p className="text-sm">
          By continuing, you agree to our{' '}
          <Link
            to="/terms"
            className="text-yellow-500 hover:text-yellow-400"
          >
            Terms
          </Link>{' '}
          and{' '}
          <Link
            to="/privacy"
            className="text-yellow-500 hover:text-yellow-400"
          >
            Privacy Policy
          </Link>
        </p>
      </footer>
    </div>
  );
}

export default EmailConfirmation;
