import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthContext from '../../utils/hooks/useAuthContext';
import Spinner from './Spinner';

interface ProtectedRouteProps {
  children: ReactNode;
}

function ProtectedRoute({ children }: ProtectedRouteProps): JSX.Element {
  const navigate = useNavigate();
  const [isChecking, setIsChecking] = useState(true);
  const { setAuthedContext } = useAuthContext();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const result = await setAuthedContext();
        if (!result) {
          navigate('/login');
        }
      } catch (error) {
        navigate('/login');
      } finally {
        setIsChecking(false);
      }
    };

    checkAuth();
  }, []);

  if (isChecking) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return <div>{children}</div>;
}

export default ProtectedRoute;
