import React, { useEffect, useRef, useState } from 'react';
import { Search } from 'lucide-react';
import Select from 'react-select';

import { useSearchCourses } from '../../../utils/api/requests';
import CourseLabel from './CourseLabel';
// @ts-ignore
import selectStyles from './selectStyles';
import { GolfCourse } from '../../../utils/api/types';

interface CourseSearchProps {
  menuOpen?: () => void;
  menuClose?: () => void;
  setSelectedCourse?: (course: GolfCourse) => void;
}
type Option = {
  value: string;
  courseObj?: GolfCourse;
  label: JSX.Element;
};
function CourseSearch({
  menuOpen = () => {},
  menuClose = () => {},
  setSelectedCourse = () => {},
}: CourseSearchProps) {
  const [searchInput, setSearchInput] = useState('');
  const { searchCourses, searchLoading, courses } = useSearchCourses();
  const [searchOptions, setSearchOptions] = useState<Option[]>();

  // Keep track of the latest request
  const latestRequestId = useRef<number>(0);
  const currentRequestId = useRef<number>(0);

  // Debounce timer reference
  const debounceTimer = useRef<NodeJS.Timeout>();

  const renderCourses = (coursesToRender: GolfCourse[]): Option[] => {
    if (!coursesToRender || coursesToRender.length === 0) return [];

    return coursesToRender.map((course) => ({
      value: course.course,
      courseObj: course,
      label: (
        <CourseLabel
          courseName={course.course}
          courseLocation={course.location}
        />
      ),
    }));
  };

  useEffect(() => {
    // Clear any existing timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Generate a new request ID
    const requestId = latestRequestId.current + 1;

    // Debounce the search
    debounceTimer.current = setTimeout(async () => {
      currentRequestId.current = requestId;

      try {
        await searchCourses({ search: searchInput });

        // Only update the results if this is still the latest request
        if (currentRequestId.current === requestId) {
          setSearchOptions(renderCourses(courses || []));
        }
      } catch (error) {
        console.error('Search failed:', error);
      }
    }, 300); // 300ms debounce delay

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [searchInput]);

  useEffect(() => {
    setSearchOptions(renderCourses(courses || []));
  }, [courses]);

  const handleInputChange = (inputValue: string, { action }: { action: string }) => {
    if (action === 'input-change') {
      setSearchInput(inputValue);
    }
  };

  return (
    <div className="relative w-full">
      <Search
        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 z-10"
        size={20}
      />
      <Select
        options={searchOptions}
        isSearchable
        styles={selectStyles}
        placeholder="Search Courses"
        className="w-full"
        onMenuOpen={menuOpen}
        onMenuClose={menuClose}
        components={{
          DropdownIndicator: null,
        }}
        filterOption={null}
        onInputChange={handleInputChange}
        isLoading={searchLoading}
        noOptionsMessage={() => 'No courses found'}
        onChange={(option: Option | null) => {
          if (option) {
            setSelectedCourse(option.courseObj as GolfCourse);
          }
        }}
      />
    </div>
  );
}

CourseSearch.defaultProps = {
  menuOpen: () => {},
  menuClose: () => {},
  setSelectedCourse: () => {},
};

export default CourseSearch;
