import React from 'react';
import Spinner from '../../utils/Spinner';

type CourseLabelProps = {
  courseName: string | null;
  courseLocation: string | null;
};

function CourseLabel(props: CourseLabelProps) {
  const { courseName, courseLocation } = props;

  return courseName && courseLocation ? (
    <div className="flex justify-between">
      <div className="course-name">{courseName}</div>
      <div className="text-sm text-right mt-auto text-gray-600 hidden sm:block">
        {courseLocation}
      </div>
    </div>
  ) : (
    <div className="flex justify-center">
      <Spinner />
    </div>
  );
}

export default CourseLabel;
