const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '0.75rem',
    fontSize: '0.875rem',
    width: '100%',
    transition: 'background-color 0.08s ease',
    backgroundColor: state.isSelected 
      ? '#eab308' // bg-yellow-500 for selected
      : state.isFocused 
        ? '#2d3748' // bg-gray-700 for hover
        : '#1f2937', // bg-gray-800 default
    color: state.isSelected ? '#111827' : '#fff', // text-gray-900 for selected, text-white default
    cursor: 'pointer',
    ':active': {
      backgroundColor: '#eab308', // bg-yellow-500
    },
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  }),
  control: (provided, state) => ({
    ...provided,
    padding: '0.75rem',
    fontSize: '1rem',
    width: '100%',
    backgroundColor: '#1f2937', // bg-gray-800
    border: '1px solid #374151', // border-gray-700
    overflow: 'hidden',
    borderRadius: '0.5rem',
    boxShadow: '0px 9px 19px 8px rgba(26,26,26,.1)',
    ':hover': {
      borderColor: '#eab308', // hover:border-yellow-500
      cursor: 'text',
    },
    ':focus': {
      borderColor: '#eab308', // focus:border-yellow-500
      boxShadow: '0 0 0 2px rgba(234, 179, 8, 0.2)', // yellow-500 with opacity
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'absolute',
    width: '100%',
    backgroundColor: '#1f2937',
    border: '1px solid #374151',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    marginTop: '0.5rem',
    borderRadius: '0.5rem',
    zIndex: 50,
    left: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    maxHeight: '200px',
    borderRadius: '0.5rem',
    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#1f2937', // bg-gray-800
    },
    '::-webkit-scrollbar-thumb': {
      background: '#4b5563', // bg-gray-600
      borderRadius: '0.5rem',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#6b7280', // bg-gray-500
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: '#fff', // text-white
    marginLeft: '1.5rem', // ml-6 to account for the search icon
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#fff', // text-white
    marginLeft: '1.5rem', // ml-6 to account for the search icon
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#9ca3af', // text-gray-400
    marginLeft: '1.5rem', // ml-6 to account for the search icon
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0.25rem',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: '#9ca3af', // text-gray-400
    ':hover': {
      color: '#eab308', // hover:text-yellow-500
    },
    cursor: 'pointer',
    padding: '0.25rem',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    color: '#9ca3af', // text-gray-400
    backgroundColor: '#1f2937', // bg-gray-800
    padding: '0.75rem',
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    color: '#9ca3af', // text-gray-400
    backgroundColor: '#1f2937', // bg-gray-800
    padding: '0.75rem',
  }),
  group: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    color: '#9ca3af', // text-gray-400
    backgroundColor: '#1f2937', // bg-gray-800
    padding: '0.75rem',
    marginBottom: 0,
    fontSize: '0.875rem',
    fontWeight: 600,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#374151', // bg-gray-700
    borderRadius: '0.25rem',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#fff', // text-white
    padding: '0.25rem',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#9ca3af', // text-gray-400
    ':hover': {
      backgroundColor: '#ef4444', // hover:bg-red-500
      color: '#fff', // hover:text-white
    },
    borderRadius: '0 0.25rem 0.25rem 0',
  }),
};

export default selectStyles;