import React, { useState } from 'react';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import { useNavigate, Link } from 'react-router-dom';
import { ArrowLeft, Eye, EyeOff, Lock, Mail } from 'lucide-react';
import Spinner from '../../components/utils/Spinner';

function ResetConfirmation() {
  const navigate = useNavigate();
  const [step, setStep] = useState<'email' | 'reset'>('email');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    code: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    const output = await resetPassword({ username: formData.email });
    console.log(output);
    setStep('reset');
    setIsLoading(false);
  };

  const handleResetSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    if (formData.newPassword !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true);

    try {
      await confirmResetPassword({
        username: formData.email,
        confirmationCode: formData.code,
        newPassword: formData.newPassword,
      });
      navigate('/login', {
        state: { message: 'Password reset successful. Please log in.' },
      });
    } catch (err: any) {
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <header className="bg-black py-4 px-6 sticky top-0 shadow-lg">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link
            to="/login"
            className="flex items-center group"
          >
            <ArrowLeft className="w-5 h-5 text-yellow-500 mr-2 transition-transform group-hover:-translate-x-1" />
            <span className="text-yellow-500 text-sm">Back to Login</span>
          </Link>
        </div>
      </header>

      <main className="flex-grow flex items-center justify-center px-4 py-8">
        <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-sm border border-gray-700">
          <div className="flex items-center justify-center mb-6">
            <img
              src="golf_bot_image.jpeg"
              alt="ForeAdvantage Golf Logo"
              className="w-12 h-12 rounded-full border-2 border-yellow-500"
            />
          </div>

          <h1 className="text-2xl font-bold text-white text-center mb-2">
            Reset Your Password
          </h1>
          <p className="text-gray-400 text-center text-sm mb-6">
            {step === 'email'
              ? 'Please confirm your email address to continue.'
              : 'Enter the verification code sent to your email and choose a new password.'}
          </p>

          {error && (
            <div className="bg-red-500/10 border border-red-500/50 text-red-500 px-4 py-3 rounded-lg text-sm">
              {error}
            </div>
          )}

          {step === 'email' ? (
            <form
              onSubmit={handleEmailSubmit}
              className="space-y-4"
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-300 mb-1"
                >
                  Email Address
                  <div className="relative mt-1">
                    <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500" />
                    <input
                      id="email"
                      name="email"
                      required
                      className="w-full pl-9 pr-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 outline-none text-white"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="you@example.com"
                    />
                  </div>
                </label>
              </div>

              <button
                type="submit"
                className="w-full px-4 py-2 bg-yellow-500 text-gray-900 rounded-lg hover:bg-yellow-400 transition-all font-medium"
              >
                Continue
              </button>
            </form>
          ) : (
            <form
              onSubmit={handleResetSubmit}
              className="space-y-4"
            >
              <div>
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-gray-300 mb-1"
                >
                  Verification Code
                  <input
                    id="code"
                    name="code"
                    type="text"
                    required
                    className="w-full px-4 py-2 mt-1 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 outline-none text-white"
                    value={formData.code}
                    onChange={handleChange}
                    placeholder="Enter code"
                  />
                </label>
              </div>

              <div>
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-medium text-gray-300 mb-1"
                >
                  New Password
                  <div className="relative mt-1">
                    <Lock className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500" />
                    <input
                      id="newPassword"
                      name="newPassword"
                      type={showPassword ? 'text' : 'password'}
                      required
                      className="w-full pl-9 pr-10 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 outline-none text-white"
                      value={formData.newPassword}
                      onChange={handleChange}
                      placeholder="••••••••"
                    />
                    <button
                      type="button"
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                    </button>
                  </div>
                </label>
              </div>

              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-300 mb-1"
                >
                  Confirm Password
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    required
                    className="w-full px-4 py-2 mt-1 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 outline-none text-white"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    placeholder="••••••••"
                  />
                </label>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full px-4 py-2 bg-yellow-500 text-gray-900 rounded-lg hover:bg-yellow-400 transition-all font-medium disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
              >
                {isLoading ? <Spinner /> : 'Reset Password'}
              </button>
            </form>
          )}

          <p className="mt-6 text-center text-gray-400 text-sm">
            {step === 'reset' && (
              <>
                Didn&apos;t receive the code?{' '}
                <button
                  type="button"
                  onClick={() => setStep('email')}
                  className="text-yellow-500 hover:text-yellow-400"
                >
                  Go back
                </button>
              </>
            )}
          </p>
        </div>
      </main>

      <footer className="bg-black py-4 text-center text-gray-400">
        <p className="text-sm">
          By continuing, you agree to our{' '}
          <Link
            to="/terms"
            className="text-yellow-500 hover:text-yellow-400"
          >
            Terms
          </Link>{' '}
          and{' '}
          <Link
            to="/privacy"
            className="text-yellow-500 hover:text-yellow-400"
          >
            Privacy Policy
          </Link>
        </p>
      </footer>
    </div>
  );
}

export default ResetConfirmation;
