import React, { useEffect, useMemo } from 'react';
import SearchesTable from '../../components/dashboard/SearchesTable';
import './dashboard.css';
import { useGetCourses, useGetSearches } from '../../utils/api/requests';
import { UserSearchInfo } from '../../utils/api/types';
import { DashboardContext } from '../../Contexts/DashboardContext';

function Dashboard() {
  const { getSearches, forceSearches, searchesLoading, searches, setSearches } =
    useGetSearches();
  const { getCourses, coursesLoading, courses } = useGetCourses();

  // Handle initial API call
  useEffect(() => {
    getCourses();
    getSearches();
  }, []);

  const handleRefreshSearches = () => {
    forceSearches();
  };
  const handleSetSearches = (updatedSearches: UserSearchInfo[]) => {
    setSearches(updatedSearches);
  };
  const dashboardContext = useMemo(
    () => ({
      refreshSearches: handleRefreshSearches,
      refreshLoading: searchesLoading,
      setSearches: handleSetSearches,
    }),
    [handleRefreshSearches, searchesLoading, handleSetSearches],
  );
  return (
    <div className="dashboardPageContainer">
      <DashboardContext.Provider value={dashboardContext}>
        <SearchesTable
          searches={searches}
          courses={courses}
          loading={searchesLoading || coursesLoading}
        />
      </DashboardContext.Provider>
    </div>
  );
}

export default Dashboard;
