import React, { useState } from 'react';
import './searchStyles.css';
import SearchInfoForm from '../../components/search/searchInformationForm/SearchInfoForm';
import { GolfCourse } from '../../utils/api/types';
import CourseSearch from '../../components/search/courseSelect/CourseSearch';

function Search() {
  const [selectedCourse, setSelectedCourse] = useState<GolfCourse | null>(null);
  return (
    <div id="homePageContainer">
      <div className="teeSearchFormContainer mt-40">
        <CourseSearch setSelectedCourse={setSelectedCourse} />
        <SearchInfoForm course={selectedCourse} />
      </div>
    </div>
  );
}

export default Search;
