import React, { useEffect, useState } from 'react';
import { ArrowLeft, Check, MapPin, Send } from 'lucide-react';
import { Turnstile } from '@marsidev/react-turnstile';
import { useRequestCourse, useGetCourseCount } from '../../utils/api/requests';
import CourseSearch from '../../components/search/courseSelect/CourseSearch';

interface RequestFormProps {
  onBack: () => void;
  className: string;
}

function RequestForm({ onBack, className }: RequestFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { requestCourse } = useRequestCourse();
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const turnstileResponse = formData.get('cf-turnstile-response');
    const email = formData.get('email');
    const golfCourse = formData.get('courseName');
    if (turnstileResponse && email && golfCourse) {
      // Make the request to add the golfcourse
      requestCourse(
        { courseName: golfCourse as string, email: email as string },
        turnstileResponse as string,
      );
    } else {
      console.error('Missing form data');
    }
    setIsSubmitting(false);
  };

  if (isSubmitted) {
    return (
      <div className={`text-center ${className}`}>
        <div className="bg-green-500 rounded-full p-3 mx-auto mb-4 w-12 h-12 flex items-center justify-center">
          <Check className="w-6 h-6 text-white" />
        </div>
        <h3 className="text-xl font-semibold mb-2">Request Submitted!</h3>
        <p className="text-gray-400 text-sm mb-6">
          We&apos;ll review your request and notify you when we add support for your course.
        </p>
        <button
          type="button"
          onClick={() => {
            setIsSubmitted(false);
            onBack();
          }}
          className="text-yellow-500 hover:text-yellow-400 transition-colors duration-200 flex items-center justify-center gap-2 mx-auto"
        >
          <ArrowLeft size={16} />
          Back to course search
        </button>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleFormSubmit}
      className={`${className} max-w-md mx-auto pt-0`}
    >
      <Turnstile siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY as string} />
      <div className="flex items-center mb-8">
        <button
          type="button"
          onClick={onBack}
          aria-label="Back to course search"
          className="text-gray-400 hover:text-yellow-500 transition-colors duration-200 mr-4"
        >
          <ArrowLeft size={20} />
        </button>
        <h3 className="text-xl font-semibold">Request New Course</h3>
      </div>

      <div className="space-y-6">
        <div>
          <label
            htmlFor="courseName"
            className="block text-sm font-medium text-gray-300 mb-2"
          >
            Course Name
            <input
              type="text"
              name="courseName"
              className="mt-2 w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:border-yellow-500 transition-colors duration-200"
              placeholder="Enter the course name"
              required
            />
          </label>
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-300 mb-2"
          >
            Your Email
            <input
              type="email"
              name="email"
              className="mt-2 w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:border-yellow-500 transition-colors duration-200"
              placeholder="you@example.com"
              required
            />
          </label>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full bg-yellow-500 hover:bg-yellow-400 text-gray-900 font-medium rounded-lg px-4 py-3 transition-colors duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting ? (
            <div className="w-6 h-6 border-2 border-gray-900 border-t-transparent rounded-full animate-spin" />
          ) : (
            <>
              <Send size={16} />
              Submit Request
            </>
          )}
        </button>
      </div>
    </form>
  );
}

function CheckSupportedCourses() {
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [overflowClass, setOverFlowClass] = useState('overflow-x-hidden');
  const { getCourseCount, courseCount, courseCountLoading } = useGetCourseCount();

  useEffect(() => {
    // Get the number of courses that are currently supported
    console.log('Calling get course count  ');
    getCourseCount();
  }, []);
  // Custom handler for Select's input change

  return (
    <div className="w-full mx-auto">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">Find Your Course</h2>
      </div>

      <div className="bg-gray-800 rounded-lg p-8 shadow-lg">
        <div className={`relative h-[300px] ${overflowClass}`}>
          <div
            className="absolute inset-0 w-[200%] flex"
            style={{
              transform: showRequestForm ? 'translateX(-50%)' : 'translateX(0)',
              transition: 'transform 300ms ease-in-out',
            }}
          >
            {/* Search Content */}
            <div className="w-1/2 flex-shrink-0">
              <div className="flex items-center justify-center mb-0">
                <div className="bg-yellow-500 rounded-full p-3 mb-4">
                  <MapPin className="w-6 h-6 text-gray-900" />
                </div>
              </div>

              <div className="text-center mb-4">
                <h2 className="text-3xl font-bold mb-4">Course Search</h2>
                <p className="text-gray-400 max-w-2xl mx-auto">
                  Search our database to see if we support your favorite golf course.
                  We&apos;re constantly adding new courses to help more golfers secure their
                  ideal tee times.
                </p>
              </div>
              <CourseSearch
                menuOpen={() => setOverFlowClass('')}
                menuClose={() => setOverFlowClass('overflow-x-hidden')}
              />
              <div className="mt-6 flex justify-between items-center text-sm px-2">
                <div className="text-gray-400">
                  {courseCountLoading ? (
                    <span className="font-semibold text-yellow-500">
                      <div className="h-4 w-12 bg-gray-200 rounded animate-pulse inline-block" />
                    </span>
                  ) : (
                    <span className="font-semibold text-yellow-500">
                      {`${courseCount?.count} `}
                    </span>
                  )}
                  courses available
                </div>
                <button
                  type="button"
                  onClick={() => setShowRequestForm(true)}
                  className="text-yellow-500 hover:text-yellow-400 transition-colors duration-200"
                >
                  Request a new course →
                </button>
              </div>
            </div>

            {/* Request Form Section - Keeping the existing code */}
            <div
              className={`w-1/2 flex-shrink-0 ${overflowClass === '' ? 'invisible' : ''}`}
            >
              <RequestForm
                className="w-full h-full"
                onBack={() => setShowRequestForm(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckSupportedCourses;
